<template>
  <div class="">
    <div style="font-size: 25px;">发起急诊</div>
    <el-divider></el-divider>
    <el-table :data="tableData1"
              style="width: 100%" border
              :header-cell-style="{'text-align':'center'}"
              :cell-style="{'text-align':'center'}">
      <el-table-column label="姓名" prop="uname"></el-table-column>

      <el-table-column label="症状备注" prop="Remark" width="200%">
        <template v-slot="scope1">
          <el-input
              type="textarea"
              :rows="1"
              placeholder="请简要描述症状"
              v-model="scope1.row.textarea" v-bind:valueText=scope1.row.textarea>
          </el-input>
        </template>
      </el-table-column>

      <el-table-column label="请求急诊" prop="Submit">
        <template v-slot="scope2">
          <el-button type="primary"
                     @click="appointmentSubmit(scope2.row)">提交</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div style="font-size: 25px;margin-top: 40px">我的急诊</div>
    <el-divider></el-divider>
    <el-table :data="urgencyData"
              style="width: 100%" border
              :header-cell-style="{'text-align':'center'}"
              :cell-style="{'text-align':'center'}">

      <el-table-column label="医生姓名" prop="docName"></el-table-column>

      <el-table-column label="急诊状态">
        <template v-slot="scope5">
          <el-button v-if="scope5.row.status==1" type="success" disabled>已接受</el-button>
          <el-button v-if="scope5.row.status==2" type="danger" disabled>已拒绝</el-button>
          <el-button v-if="scope5.row.status==3&&scope5.row.userType=='医生'" type="primary" disabled>待接受</el-button>
          <el-button v-if="scope5.row.status==3&&scope5.row.userType=='个人'" type="primary">接受</el-button>
        </template>
      </el-table-column>

      <el-table-column label="发起时间" prop="creatTime"></el-table-column>

      <el-table-column label="加入诊室">
        <template v-slot="scope6">
          <el-button v-if="scope6.row.inviteLink!=''" type="primary">立即加入</el-button>
          <el-button v-if="scope6.row.inviteLink==''" type="primary" disabled>等待创建</el-button>
        </template>
      </el-table-column>

    </el-table>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
//import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
//这里存放数据
    return {
      search: '',
      emergency_list: [],
      emergency_history: [],
      valueText:'',
      userId: this.$store.state.women_baby.uid,
      userType: '个人',
      uname: this.$store.state.women_baby.username,
      tableData1:[{
        uname:"zhangyifan",
        textarea:'',
    },],
      urgencyData:[
        {
          docName:"wxy",
          status:1,
          userType:"个人",
          creatTime:"Wed Aug 21 2019 10:00:58 GMT+0800 (中国标准时间)",
          inviteLink:"www.baidu.com",
        },
      ],
    };
  },
//监听属性 类似于data概念
  computed: {},
//监控data中的数据变化
  watch: {},
//方法集合
  methods: {
    appointmentSubmit(Obj){
      this.$http({
        url: this.$http.adornUrl(''),
        method: 'post',
        data: this.$http.adornData({
          userId: this.userId,
          userType: this.userType,
          uname: this.uname,
          docId: 3,
          symptoms: Obj.textarea,
          dateTime: new Date(),
        })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          console.log("看看提交后返回的东西")
          console.log(data)
          this.$notify({
            title: '成功',
            message: '提交成功',
            type: 'success'
          })
        } else {
          this.$message.error(data.msg)
        }
      })
    },
  },
//生命周期 - 创建完成（可以访问当前this实例）
  created() {

  },
//生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped>

</style>